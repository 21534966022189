import $ from 'jquery'
import tracking from "../tracking";

export default class autoGTag {

  constructor(element) {
    this.$element = $(element)
    this.tagType = this.$element.data('tagType')
    this.tagSubType = this.$element.data('tagSubType')
    this.trackingData = this.$element.data('trackingData')
    tracking.onTrackingAllowed(this.trackData.bind(this))
  }

  trackData() {
    window.gtagImmediate(this.tagType, this.tagSubType, this.trackingData)
  }

  destroy() {

  }

}
