import $ from 'jquery'
import selectChoices from "./selectChoices";

export default class selectChoicesUpdateFlowerInfo extends selectChoices {

  constructor (element) {
    super(element);
    this.$select.on('change.selectChoicesUpdateFlowerInfo', this.handleChange.bind(this))
    this.fetchUrl = this.$element.data('fetchUrl')
    this.widgetContainerSelector = this.$element.data('widgetContainerSelector')
    this.$widgetContainer = $(this.widgetContainerSelector);
    this.handleChange();
  }

  handleChange() {
    const flowerId = this.$select.val();
    if (!flowerId) {
      this.$widgetContainer.html('')
      return
    }

    const url = this.fetchUrl
      .replace('_flowerId_', flowerId)

    const ajaxSettings = {
      url: url,
      method: 'post',
      cache: false,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: {}
    }

    $.ajax(ajaxSettings)
      .done(this.handleRequestDone.bind(this))
  }


  handleResponse (status, responseData, rawData) {
    if (status === 200) {
      if (responseData.hasOwnProperty('widget')) {
        this.$widgetContainer.html(responseData.widget)
      }
    }
  }

  handleRequestDone(data, textStatus, jqXHR) {
    this.handleResponse(jqXHR.status, data, jqXHR.responseText)
  }
}
