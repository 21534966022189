import $ from 'jquery'
import Cleave from 'cleave.js'

export default class inputCleave {
  constructor (element) {
    this.$element = $(element)
    this.cleaveConfig = this.$element.data('cleavesConfig');
    this.$input = this.$element.find('input')
    this.codeCleave = new Cleave(this.$input.get(0), this.cleaveConfig)
  }
}
