/* global localStorage */
import $ from 'jquery'
import CookiesEuBanner from 'cookies-eu-banner'
import ExternalModules from './externalModules'

const Tracking = class Tracking {
  constructor () {
    this.allowTrackingCallbacks = []
    this.alreadyAllowed = false


    // USE window.pushIntoDataLayer to push properly into dataLayer!
    // dont push into datalayer with
    // window.dataLayer.push(data)
    // it needs to be syncronized
    window.pushIntoDataLayer = function(data) {
      this.onTrackingAllowed(function() {
        window.dataLayer.push(data)
      })
    }.bind(this)

    window.pushIntoGtag = function() {
      const data = arguments;
      this.onTrackingAllowed(function() {
        window.dataLayer.push(data);
      })
    }.bind(this)

    window.gtagImmediate = function() {
      window.dataLayer.push(arguments);
    }

    window.gtagImmediate('consent', 'default', {
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
      'functionality_storage': 'denied',
      'personalization_storage': 'denied',
      'security_storage': 'denied',

      'gm_analytics': 'denied',
      'gm_facebook': 'denied',
      'gm_gads': 'denied',
    });
  }

  initialize () {


    ExternalModules.addSelfGDPRAware()

    // remove old (maybe existing)
    if (localStorage) {
      localStorage.removeItem('hasConsent')
    }

    this.$consentDisplay = $('.cookieConsentDisplay')
    this.$buttons = $('.cookieConsentButtonBar button')

    this.$buttons.on('click', this.handleButtonClick.bind(this))

    this.$resetButtons = $('.consentResetButton')
    this.$resetButtons.on('click', this.handleResetButtonClick.bind(this))

    setTimeout(this.showConsentBar.bind(this), 1000)

    const that = this
    this.cookiesBanner = new CookiesEuBanner(function () {
      ExternalModules.addNonGDPR()
      for (const callback of that.allowTrackingCallbacks) {
        callback()
      }
      that.alreadyAllowed = true
    }, true, false)
  }

  handleButtonClick () {
    this.hideConsentBar()
  }

  hideConsentBar () {
    this.$consentDisplay.removeClass('show')
  }

  showConsentBar () {
    this.$consentDisplay.addClass('show')
  }

  resetConsent () {
    const hasContent = this.cookiesBanner.hasConsent()
    if (hasContent !== null) {
      this.cookiesBanner.deleteCookie(this.cookiesBanner.cookieName)
      window.setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  onTrackingAllowed (callback) {
    if (this.alreadyAllowed) {
      callback()
    } else {
      this.allowTrackingCallbacks.push(callback)
    }
  };

  handleResetButtonClick (event) {
    event.preventDefault()
    this.reset()
  }

  reset () {
    if (this.cookiesBanner) {
      this.cookiesBanner.deleteCookie(this.cookiesBanner.cookieName)
      window.location.reload()
    }
  }
}

export default new Tracking()
