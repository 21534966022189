import $ from 'jquery'
import Behaviors from "../behaviors";

export default class miniBasketItem {
  constructor (element) {
    this.$element = $(element)

    this.$flyout = $('.miniBasket')
    this.$spinner = this.$flyout.find('.loadingSpinner')

    this.$removeButton = this.$element.find('.miniBasketItemRemoveButton')
    this.$removeButton.on('click', this.handleRemoveItemButtonClick.bind(this))
  }

  handleRemoveItemButtonClick(event) {
    event.preventDefault()

    // setting the loading spinner ONLY to active
    // removeing of an imtem leads to refreshFlyoutContent
    // wich deavtivates the spinner on its own!
    this.$spinner.addClass('active')

    $.ajax({
      url: this.$removeButton.attr('href'),
      method: 'get',
      cache: false,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
    }).done(this.handleRemoveItemRequestDone.bind(this))
      .fail(this.handleRemoveItemRequestFail.bind(this))

  }

  handleRemoveItemResponse(status, data, responseText) {
    if (data.hasOwnProperty('status')) {
      if (data.status === 'ok') {
        this.$element.remove()
      }
    }
    const formBehavior = Behaviors.getBehaviorFromElement(this.$flyout.get(0))
    if (formBehavior) {
      formBehavior.behavior.refreshFlyoutContent()
    }
  }

  handleRemoveItemRequestDone (data, textStatus, jqXHR) {
    this.handleRemoveItemResponse(jqXHR.status, data, jqXHR.responseText)
  }

  handleRemoveItemRequestFail (jqXHR, textStatus, errorThrown) {
    let data = {}
    try {
      if (jqXHR.responseText) {
        data = JSON.parse(jqXHR.responseText)
      }
    } catch (e) {
      console.error(jqXHR.responseText)
    }
    this.handleRemoveItemResponse(jqXHR.status, data, jqXHR.responseText)
  }



}
