import $ from 'jquery'
import Behaviors from '../behaviors'

export default class infiniteScroll {
  constructor (element) {
    this.$element = $(element);

    this.contentContainerSelector = this.$element.data('contentContainerSelector')
    this.$contentContainer = $(this.contentContainerSelector)
    this.loadingSpinnerSelector = this.$element.data('loadingSpinnerSelector')
    this.$loadingSpinner = this.$element.find(this.loadingSpinnerSelector)
    this.paginationContainerSelector = this.$element.data('paginationContainerSelector')
    this.$paginationContainer = this.$element.find(this.paginationContainerSelector)

    this.fetchUrl = this.$element.data('fetchUrl')
    this.fetchMethod = this.$element.data('fetchMethod')
    this.pushHistory = this.$element.data('pushHistory')
    this.$form = this.$element.find('form')
    this.currentPage = this.$element.data('initPage')
    this.itemsPerPage = this.$element.data('itemsPerPage')

    this.noMoreItems = false
    this.loadingInProgress = false;

    this.forceToCount = this.itemsPerPage;

    this.init()
    this.forceItemsToCount()

  }

  init() {
    window.addEventListener('scroll', () => {
      var scrollableContent = this.$contentContainer.get(0);
      var hasReachedBottom = scrollableContent.getBoundingClientRect().bottom <= window.innerHeight;

      if (hasReachedBottom) {
        if (!this.noMoreItems) {
          this.loadMore()
        }
      }
    });
  }

  loadMore() {
    if (this.loadingInProgress) {
      return
    }
    this.loadingInProgress = true

    this.$paginationContainer.addClass('infinityMode')
    this.$loadingSpinner.addClass('active');

    this.currentPage++;

    const ajaxSettings = {
      url: this.fetchUrl.replace('_page_', this.currentPage),
      method: this.fetchMethod,
      cache: false,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: {}
    }

    $.ajax(ajaxSettings)
      .done(this.handleRequestDone.bind(this))
      .fail(this.handleRequestFail.bind(this))
      .always(() => {
        this.loadingInProgress = false
        this.$loadingSpinner.removeClass('active');
      })
  }


  handleResponse (status, responseData, rawData) {
    if (status === 200) {
      if (responseData.hasOwnProperty('infiniteScrollItems')) {
        if (responseData.infiniteScrollItems.length < 1) {
          this.noMoreItems = true
          return
        }
        // console.log('detachBehaviors')
        Behaviors.detachBehaviors(this.$contentContainer.get(0))

        // add items
        for (const item of responseData.infiniteScrollItems) {
          this.$contentContainer.append(item);
        }

        this.forceItemsToCount()

        if (this.pushHistory) {
          var historyUrl = this.fetchUrl.replace('_page_', this.currentPage)
          const queryString = historyUrl.substr(historyUrl.indexOf('?')+1);
          const params = new URLSearchParams(queryString);
          params.delete('ajax')
          params.delete('page')
          params.append('infinityPage', this.currentPage)
          history.pushState({ infinityPage: this.currentPage}, "", '?'+params.toString());
        }

        // console.log('attachBehaviors')
        Behaviors.attachBehaviors(this.$contentContainer.get(0))
      }
    }
  }

  forceItemsToCount() {
    // remove dummy items
    this.$contentContainer.find('.filler').remove();

    const countItems = this.$contentContainer.children().length
    const countFillerItems = this.forceToCount - (countItems % this.forceToCount)

    for(let i = 0; i<countFillerItems; i++) {
      this.$contentContainer.append('<div class="tileSelectTile tileSelectFlower filler"></div>');
    }

  }

  handleRequestDone(data, textStatus, jqXHR) {
    this.handleResponse(jqXHR.status, data, jqXHR.responseText)
  }

  handleRequestFail() {
    let data = {}
    try {
      if (jqXHR.responseText) {
        data = JSON.parse(jqXHR.responseText)
      }
    } catch (e) {
      console.error(jqXHR.responseText)
    }
    this.handleResponse(jqXHR.status, data, jqXHR.responseText)
  }

}
