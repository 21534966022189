import $ from 'jquery'

export default class slugCreator {
  constructor (element) {
      this.$element = $(element)
      this.$form = this.$element.closest("form")
      // example: data-source-field-selector="input[name='title'],input[name='strain']"
      this.selectors = this.$element.data('sourceFieldSelector')
      this.$sourceFields = this.$form.find(this.selectors);

      this.$targetField = this.$element.find('input')
      this.$button = this.$element.find('a.createSlug')
      this.$button.on('click', this.handleButtonClick.bind(this))
  }

  handleButtonClick (event) {
      event.preventDefault()

      let sourceStrings = [];
      this.$sourceFields.each(function(idx, item) {
        const $item = $(item);
        switch ($item.prop("tagName")) {
          case 'TEXTAREA':
          case 'INPUT':
            sourceStrings.push($(item).val());
            break;
          case 'SELECT':
            const val = $(item).val();
            sourceStrings.push($(item).find(`option[value=${val}]`).text());
            break;
        }
      })

      sourceStrings = sourceStrings.map(function(slugString) {
        slugString = slugString
          .toLowerCase()
          .replace(/ä/g, 'ae')
          .replace(/ö/g, 'oe')
          .replace(/ü/g, 'ue')

        let slugChars = slugString.split("");

        let newSlug = ''
        let lastChar = ''

        for (let char of slugChars){
          if (!((char.charCodeAt(0) >= 97 && char.charCodeAt(0) <= 122) ||
            (char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57))) {
            char = "_"
          }
          if (lastChar === "_" && lastChar === char) continue;
          newSlug = newSlug + char
          lastChar = char
        }

        return newSlug
      });

      this.$targetField.val(sourceStrings.join('-'))
  }
}
