import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'

export default class modalAutoOpen {
  constructor (element) {
    this.$element = $(element)
    this.$modal = this.$element

    this.modal = new Modal(this.$modal.get(0), {})
    this.modal.show();

    this.$allButtons = this.$modal.find('.modal-body .btn')
    this.$allButtons.on('click.modalAutoOpen', this.handleButtonClick.bind(this))

  }

  handleButtonClick(event) {
    const $button = $(event.target)
    const href = $button.attr('href')
    if (href === '#close') {
      event.preventDefault();
      this.modal.hide();
    }
  }

  destroy() {
    this.$allButtons.off('click.modalAutoOpen')
  }

}
