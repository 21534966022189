const tempusDominusDateConfig = {
  // allowInputToggle: false,
  keepInvalid: true,
  dateRange: false,
  multipleDatesSeparator: '',

  display: {
    sideBySide: false,
    viewMode: 'calendar',
    keepOpen: true,
    buttons: {
      today: true,
      clear: false,
      close: true
    },

    components: {
      calendar: true,
      date: true,
      month: true,
      year: true,

      clock: false,
      hours: false,
      minutes: false,
      seconds: false
    },
    theme: 'light'
  },

  localization: {
    startOfTheWeek: 1,
    dateFormats: {
      // LTS: 'hh:mm:ss T',
      LT: 'H:mm',
      L: 'dd.MM.yyyy'
      // LL: 'MMMM d, yyyy',
      // LLL: 'MMMM d, yyyy h:mm T',
      // LLLL: 'dddd, MMMM d, yyyy h:mm T'
    },
    format: 'L',
    locale: 'de',
    hourCycle: 'h23'
  }
}

const tempusDominusDateTimeConfig = JSON.parse(JSON.stringify(tempusDominusDateConfig)) // deep clone
tempusDominusDateTimeConfig.localization.format = 'L LT'
tempusDominusDateTimeConfig.display.sideBySide = true
tempusDominusDateTimeConfig.display.components.clock = true
tempusDominusDateTimeConfig.display.components.hours = true
tempusDominusDateTimeConfig.display.components.minutes = true
tempusDominusDateTimeConfig.display.components.seconds = false
// tempusDominusDateTimeConfig.display.theme = ''

const tempusDominusDateRangeConfig = JSON.parse(JSON.stringify(tempusDominusDateConfig)) // deep clone
tempusDominusDateRangeConfig.dateRange = true
// tempusDominusDateRangeConfig.display.sideBySide = true
tempusDominusDateRangeConfig.multipleDatesSeparator = ' - '

export const DateConfig = tempusDominusDateConfig
export const DateTimeConfig = tempusDominusDateTimeConfig
export const DateRangeConfig = tempusDominusDateRangeConfig
