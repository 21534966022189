import $ from 'jquery'

export default class expandableContainer {

  constructor(element) {
    this.$element = $(element)
    this.$element.addClass('expandableContainer')

    this.$button = $(`<button class="btn expandButton"></button>`)
    this.$element.prepend(this.$button)

    this.$button.on('click', this.handleButtonClick.bind(this))
  }

  handleButtonClick(event) {
    event.preventDefault()
    this.$element.toggleClass('expanded')
  }

  destroy() {

  }


}
