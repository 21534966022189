import $ from 'jquery'
import Behaviors from "../behaviors";
import Dropdown from 'bootstrap/js/src/dropdown'

export default class miniBasket {
  constructor (element) {
    this.$element = $(element)

    this.$addToCartForm = $('form#formaddToBasket')
    this.addToCartFlowerId = this.$addToCartForm.find('input[name=flowerId]').val()

    this.$miniBasketButton = this.$element.find('.miniBasketButton')
    this.$miniBasketFlyout = this.$element.find('.miniBasketFlyout')
    this.$dropdown = this.$element.find('.dropdown')
    this.refreshUrl = this.$element.data('refreshUrl')
    this.$spinner = this.$element.find('.loadingSpinner')
    this.$summaryItemCount = this.$element.find('.basketFlyoutSummaryItemCount')
    this.$miniBasketControl = this.$element.find('.miniBasketControl')
    this.$miniBasketShowLabel = this.$element.find('.miniBasketShowLabel')
    this.$summaryItemCountNoItem = this.$summaryItemCount.filter('.noItem')
    this.$summaryItemCountOneItem = this.$summaryItemCount.filter('.oneItem')
    this.$summaryItemCountNItems = this.$summaryItemCount.filter('.nItems')
    this.$summaryItemCountNItemsTextMacro = this.$summaryItemCountNItems.data('textSource')

    this.$basketFlyout = this.$element.find('.shoppingBasketFlyout')

    this.closeTimeout = null
    this.$miniBasketButton.on('click',      this.handleButtonClick.bind(this))
    this.$miniBasketButton.on('mouseenter', this.handleButtonMouseEnter.bind(this))
    this.$miniBasketButton.on('mouseleave', this.handleButtonMouseLeave.bind(this))
    this.$miniBasketFlyout.on('mouseenter', this.handleDropdownMouseEnter.bind(this))
    this.$miniBasketFlyout.on('mouseleave', this.handleDropdownMouseLeave.bind(this))

    this.$clearButton = this.$miniBasketControl.find('a.clearCart')
    this.$clearButton.on('click', this.handleClearCartButtonClick.bind(this))
    this.clearUrl = this.$clearButton.attr('href')

    this.refreshFlyoutContent()

    this.dropdownOpenState = this.$miniBasketFlyout.hasClass('show')

    this.$dropdown.get(0).addEventListener('show.bs.dropdown', this.handleDropdownShow.bind(this))
    this.$dropdown.get(0).addEventListener('hide.bs.dropdown', this.handleDropdownHide.bind(this))

    this.dropdown = new Dropdown(this.$dropdown.get(0), {
      autoClose: 'outside',
      offset: [0,0]
    })
  }

  handleClearCartButtonClick(event) {
    event.preventDefault()
    this.showLoadingSpinner();
    $.ajax({
        url: this.clearUrl,
        method: 'get',
        cache: false,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8'
      })
      .always(() => {
        this.refreshFlyoutContent()
      })
  }

  handleDropdownShow() {
    this.dropdownOpenState = true
  }

  handleDropdownHide() {
    this.dropdownOpenState = false
  }

  getItemCount() {
    return this.$element.find('.miniBasketItem').length;
  }
  isDropdownOpen () {
    return this.dropdownOpenState
  }

  closeDropdown() {
    this.closeTimeout = window.setTimeout(this._closeDropdown.bind(this), 250)
  }

  _closeDropdown() {
    if (this.isDropdownOpen()) {
      this.dropdown.hide()
    }
  }

  openDropdown() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout)
    }
    if (!this.isDropdownOpen()) {
      this.dropdown.show()
    }
  }

  handleButtonClick(event) {
    if (event.isPropagationStopped()) {
      return
    }
    if (!this.dropdownOpenState) {
      this.openDropdown();
    } else {
      this._closeDropdown();
    }
  }

  handleButtonMouseEnter(event){
    event.stopImmediatePropagation()
    this.openDropdown();
  }

  handleButtonMouseLeave(event) {
    this.closeDropdown()

  }

  handleDropdownMouseEnter(event) {
    this.openDropdown();
  }

  handleDropdownMouseLeave(event) {
    this.closeDropdown()
  }

  refreshFlyoutContent() {
    if (!this.refreshInProgress) {
      this.refreshInProgress = true
      this.showLoadingSpinner();
      $.ajax({
          url: this.refreshUrl,
          method: 'get',
          cache: false,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8'
        })
        .done(this.handleRefreshFlyoutDone.bind(this))
        .always(() => {
          this.refreshInProgress = false
          this.hideLoadingSpinner()
        })
    }
  }

  showLoadingSpinner() {
    this.$spinner.addClass('active')
  }

  hideLoadingSpinner() {
    this.$spinner.removeClass('active')
  }

  handleRefreshFlyoutDone (data, textStatus, jqXHR) {

    if (data.hasOwnProperty('basketFlyout')) {
      Behaviors.detachBehaviors(this.$element.get(0))
      this.$basketFlyout.html(data.basketFlyout)
      Behaviors.attachBehaviors(this.$element.get(0))
    }

    if (data.hasOwnProperty('basketItemCount')) {
      this.$summaryItemCount.removeClass('active')
      if (data.basketItemCount === 0) {
        this.$miniBasketShowLabel.removeClass('active')
        this.$miniBasketButton.attr('disabled', true)
        this.$summaryItemCountNoItem.addClass('active')
      } else if (data.basketItemCount === 1) {
        this.$miniBasketShowLabel.addClass('active')
        this.$miniBasketButton.attr('disabled', false)
        this.$summaryItemCountOneItem.addClass('active')
      } else {
        this.$miniBasketShowLabel.addClass('active')
        this.$miniBasketButton.attr('disabled', false)
        this.$summaryItemCountNItems
          .text(this.$summaryItemCountNItemsTextMacro.replace('%count%', data.basketItemCount))
          .addClass('active')
      }
    }
    if (this.addToCartFlowerId) {
      let currentPDPInBasket = false;
      if (data.hasOwnProperty('basketItemIds')) {
        for (const flowerId of data.basketItemIds) {
          if (flowerId === this.addToCartFlowerId) {
            currentPDPInBasket = true
          }
        }
      }

      if (currentPDPInBasket) {
        this.$addToCartForm.addClass('flowerExistsInBasket')
      } else {
        this.$addToCartForm.removeClass('flowerExistsInBasket')
      }
    }


    if (this.getItemCount() < 1) {
      this.$miniBasketControl.removeClass('active')
      this._closeDropdown()
    } else {
      this.$miniBasketControl.addClass('active')
    }

    this.$element.addClass('populated')
  }

}
