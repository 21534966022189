import $ from 'jquery'

export default class cockpitAutoModalClose {
  constructor (element) {
    this.$element = $(element)
    this.$button = this.$element.find('button')
    this.$button.on('click', this.handleClick.bind(this))
    // console.log(element)
    // console.log(this.$button)
  }

  handleClick (event) {
    this.$element.removeClass('show')
    event.preventDefault()
  }
}
