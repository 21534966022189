import $ from 'jquery'

export default class formColorUpdate {
  constructor (element) {
    this.$element = $(element)
    this.$input = this.$element.find('input');
    this.$text = this.$element.find('.input-group-text');
    this.$input.on('change', this.handleChange.bind(this))
    this.$input.on('input', this.handleChange.bind(this))
  }

  handleChange (event) {
    this.$text.text(this.$input.val())
  }

}
