// noinspection LanguageDetectionInspection

import $ from 'jquery'

export default class DropdownToggle {
  constructor (element) {
    this.$element = $(element)
    this.$dropdowns = this.$element.find('li.dropdown')
    this.$dropdowns.find('a:first').on('click', this.handleClick.bind(this))
  }

  handleClick (event) {
    event.preventDefault()
    const $link = $(event.target)
    const $ulElement = $link.closest('li.dropdown').find('ul:first')
    $ulElement.toggleClass('dropdown-active')
  }

  destroy() {

  }
}
