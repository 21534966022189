import $ from 'jquery'
import Behaviors from "../behaviors";

export default class loadAsyncContent {

    constructor(element) {
        this.$container = $(element)
        this.asyncContentUri =  this.$container.data('asyncContentUri')
        this.loadContent()
    }

    loadContent(){
        const ajaxSettings = {
            url: this.asyncContentUri,
            method: 'get',
            cache: false,
            dataType: 'html',
            // contentType: 'application/json; charset=utf-8',
            headers: {}
        }

        $.ajax(ajaxSettings)
            .done(this.handleRequestDone.bind(this))
            .fail(this.handleRequestFail.bind(this))
            .always(() => {
                this.loadingInProgress = false
                // this.$loadingSpinner.removeClass('active');
            })
    }

    handleResponse (status, responseData, rawData) {
        // console.log(status, responseData, rawData)
        if (status === 200) {
                this.$container.html(responseData)
                // console.log('attachBehaviors')
                Behaviors.attachBehaviors(this.$container.get(0))

        }
    }


    handleRequestDone(data, textStatus, jqXHR) {
        this.handleResponse(jqXHR.status, data, jqXHR.responseText)
    }

    handleRequestFail() {
        let data = {}
        try {
            if (jqXHR.responseText) {
                data = JSON.parse(jqXHR.responseText)
            }
        } catch (e) {
            console.error(jqXHR.responseText)
        }
        this.handleResponse(jqXHR.status, data, jqXHR.responseText)
    }

}
