import $ from 'jquery'

export default class tableClickRow {
  constructor (element) {
    this.$element = $(element)

    this.$trs = this.$element.find('tr');
    this.$trs.on('click', this.handleTrClick.bind(this))
  }

  handleTrClick(event) {
    const $target =$(event.target);
    const $tr = $target.closest('tr')
    const $link = $tr.find('a').eq(0)
    window.location.href = $link.attr('href')
  }

  destroy() {

  }
}
