import $ from 'jquery'

export default class cannabisFlowerScalePrice {
  constructor(element) {
    this.$element = $(element)
    this.priceInputSelector = this.$element.data('priceInputSelector')
    this.$priceInput = $(this.priceInputSelector)

    this.price = parseFloat(this.$element.data('price'))

    this.$flowerSinglePrice = this.$element.closest('.flowerTilePropListItem').find('.flowerSinglePrice')
    this.template = this.$element.data('template')

    this.$priceInput.on('change', this.updatePrice.bind(this))
    this.$priceInput.on('input', this.updatePrice.bind(this))
    this.updatePrice()
  }

  updatePrice (event) {
    const amount = parseInt(this.$priceInput.val(), 10); // in g
    if (amount <= 0) {
      this.$flowerSinglePrice.removeClass('parentheses')
      this.$element.hide()
      return
    }

    const priceAmount = amount * this.price;
    const priceString = priceAmount.toFixed(2)
      .replace(".", ",")
    this.$element.text(this.template
      .replace('%price%', priceString)
      .replace('%amount%', amount)
    )
    this.$flowerSinglePrice.addClass('parentheses')
    this.$element.show()
  }

  destroy() {

  }

}
