import $ from 'jquery'
import formRangeWithValue from "./formRangeWithValue";

export default class formRangeWithImage extends formRangeWithValue {
  constructor(element) {
    super(element);
    this.$valueIconContainer = this.$element.find('.valueIconContainer').eq(0);
    this.updateIcon()
  }

  handleInputChange(event) {
    this.updateIcon();
    this.displayValue();
  }

  updateIcon() {
    const value = this.$input.val()
    this.$valueIconContainer.html(`<div class="valueIcon valueIcon${value}"></div>`)
  }
  destroy() {

  }

}
