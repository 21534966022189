import $ from 'jquery'
import tracking from "../tracking";

export default class autoDataLayer {

  constructor(element) {
    this.$element = $(element)
    this.trackingData = this.$element.data('dataLayer')
    tracking.onTrackingAllowed(this.trackData.bind(this))
  }

  trackData() {
    window.pushIntoDataLayer(this.trackingData)
  }

  destroy() {

  }

}
