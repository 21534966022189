import $ from 'jquery'

export default class formUnselectableRadioInput {

  constructor (element) {
    this.$element = $(element)

    this.$radioInputs = this.$element.find('input[type="radio"]')
    this.$radioLabels = this.$element.find('label[for]')

    this.$radioInputs.on('change', this.handleFieldChange.bind(this))
    this.$radioInputs.on('click', this.handleRadioClick.bind(this))
    this.$radioInputs.on('keypress', this.handleRadioKeypress.bind(this))

    this.$emptyRadioInput = this.$element.find('input[value=""]')

    this.currentValue = ''
    const $initialSelected = this.$radioInputs.filter(':checked')
    if ($initialSelected.length > 0) {
      this.currentValue = $initialSelected.attr('value')
    }
  }

  handleFieldChange(event) {
    const $radio = $(event.target)
    this.currentValue = $radio.val()
  }

  handleRadioClick (event) {
    const $radio = $(event.target)
    this.activateRadio($radio)
  }

  handleRadioKeypress (event) {
    if (event.keyCode !== 32) {
      return
    }
    const $radio = $(event.target)
    $radio.trigger('click')
  }

  activateRadio ($radio) {
    let newValue = $radio.val()

    if (newValue === '') { // empty fields cant be unsetted
      return
    }
    if (newValue === this.currentValue) {
      this.$emptyRadioInput.prop('checked', true)
      this.$emptyRadioInput.trigger('change')
      newValue = ''
    }

    this.currentValue = newValue
  }
  destroy() {

  }

}
