import $ from 'jquery'

export default class workflowScrollTop {
  constructor(element) {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
  }
  destroy() {

  }

}
