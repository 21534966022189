import $ from 'jquery'

export default class mainNavCollapseMobile {
  constructor (element) {
    this.$element = $(element)
    this.$parentLi = this.$element.closest('li.nav-item')

    this.$element.on('click', this.handleClick.bind(this))
  }

  handleClick (event) {
    event.preventDefault()
    this.$parentLi.toggleClass('mobileOpen')
  }
}
