import $ from 'jquery'
import WorkflowNavigation from "./workflowNavigation";

export default class workflowNavigationReset extends WorkflowNavigation {
  constructor (element) {
    super(element);
    const resetFieldName = this.$element.data('resetFieldName');
    this.$voucherField = this.$form.find(`input[name="${resetFieldName}"]`)
  }

  handleClick (event) {
    this.$voucherField.val('');
    super.handleClick(event)
  }

  destroy() {
    this.$element.off('change.workflowNavigationOnSelect')
  }

}
