export default new class externalModules {
  addNonGDPR () {
    // this.hubSpot();
    window.gtagImmediate('consent', 'update', {
      'ad_personalization': 'granted',
      'ad_user_data': 'granted',

      'ad_storage': 'granted',
      'analytics_storage': 'granted',

      'gm_analytics': 'granted',
      'gm_facebook': 'granted',
      'gm_gads': 'granted',
    });

    window.pushIntoDataLayer({
      'event': 'gm_tracking_init'
    });

  }

  addSelfGDPRAware() {
  }


}()
