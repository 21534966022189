import $ from 'jquery'

export default class dataTable {

  constructor (element) {
    this.$element = $(element)
    this.id = `datatable_${this.makeid(10)}`;
    this.$element.attr('id', this.id)

    this.libCore = null;
    this.libButtons = null;
    this.libResponsive = null;
    import(/* webpackChunkName: "datatables" */ 'datatables.net-bs5').then((module) => {
      this.libCore = module.default;
      this.initialize();
    })
    import(/* webpackChunkName: "datatables" */ 'datatables.net-buttons-dt').then((module) => {
      this.libButtons = module;
      this.initialize();
    })
    import(/* webpackChunkName: "datatables" */ 'datatables.net-responsive-dt').then((module) => {
      this.libResponsive = module;
      this.initialize();
    })
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }


  initialize() {
    if (!this.libCore) {
      console.log('libCoreReady not ready');
      return
    }
    if (!this.libButtons) {
      console.log('libButtonsReady not ready');
      return
    }
    if (!this.libResponsive) {
      console.log('libResponsiveReady not ready');
      return
    }

    this.table = new this.libCore(`#${this.id}`, {
      scrollX: true
    });

  }
}
