import $ from 'jquery'

export default class formRangeWithValue {
  constructor (element) {
    this.$element = $(element)
    this.$input = this.$element.find('input').eq(0);
    this.$valueField = this.$element.find('.showValue');
    this.$input.on('change', this.handleInputChange.bind(this));
    this.$input.on('input', this.handleInputChange.bind(this));

    const buttonPlusId = this.$element.data('plusButtonId')
    this.$buttonPlus = this.$element.find(`#${buttonPlusId}`)
    this.$buttonPlus.on('click', this.handlePlusButtonClick.bind(this))

    const buttonMinusId = this.$element.data('minusButtonId')
    this.$buttonMinus = this.$element.find(`#${buttonMinusId}`)
    this.$buttonMinus.on('click', this.handleMinusButtonClick.bind(this))

    this.buttonStepSize = parseInt(this.$element.data('buttonStepSize'), 10)

    this.displayValue();
  }

  handlePlusButtonClick(event) {
    this.$buttonPlus.addClass('')

    event.preventDefault()
    const max = parseInt(this.$input.attr('max'), 10)
    let newVal = parseInt(this.$input.val(), 10) + this.buttonStepSize;
    if (newVal > max) {
      newVal = max
    }
    this.$input.val(newVal)
    this.$input.trigger('change')
  }

  handleMinusButtonClick(event) {
    event.preventDefault()
    const min = parseInt(this.$input.attr('min'), 10)
    let newVal = parseInt(this.$input.val(), 10) - this.buttonStepSize;
    if (newVal < min) {
      newVal = min
    }
    this.$input.val(newVal)
    this.$input.trigger('change')
  }

  handleInputChange(event) {
    this.displayValue();
  }

  displayValue() {
    const value = this.$input.val()
    this.$valueField.text(value)
  }


  destroy() {

  }

}
