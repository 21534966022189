// noinspection LanguageDetectionInspection

import $ from 'jquery'

export default class hideContainerOnClick {
  constructor (element) {
    this.$element = $(element)
    const hideContainerSelector = this.$element.data('hideContainerSelector')
    this.$hideContainer = $(hideContainerSelector)
    // console.log(hideContainerSelector)
    // console.log(this.$hideContainer)
    this.$element.on('click.hideContainerOnClick', this.handleClick.bind(this))
  }

  handleClick (event) {
    this.$hideContainer.hide()
  }

  destroy() {

  }
}
