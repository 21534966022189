import $ from 'jquery'
import IntlTelInput from 'intl-tel-input' // see https://github.com/jackocnr/intl-tel-input
import Behaviors from './../behaviors'

export default class formPhoneInput {
  constructor (element) {
    this.$element = $(element)
    this.$field = this.$element.find('input.form-control')
    this.fieldName = this.$field.attr('name')
    this.$form = this.$element.closest('form')

    this.initialCountry = this.$element.data('initialCountry')
    this.localizedCountries = this.$element.data('localizedCountries')
    this.errorMap = this.$element.data('validatorMessages')

    this.intlTelInput = IntlTelInput(this.$field.get(0), {
      utilsScript: '/dist/js/intl-tel-utils.js',
      initialCountry: this.initialCountry,
      hiddenField: this.fieldName,
      localizedCountries: this.localizedCountries,
      autoPlaceholder: 'off'
    // any initialisation options go here
    })

    this.registerField()
  }

  serialize () {
    return this.intlTelInput.getNumber()
  }

  validate () {
    if (this.$field.val().trim()) {
      if (!this.intlTelInput.isValidNumber()) {
        return this.errorMap[this.intlTelInput.getValidationError()]
      }
    }
    return null
  }

  registerField () {
    const formBehavior = Behaviors.getBehaviorFromElement(this.$form.get(0))

    // if not available - try again
    if (!formBehavior) {
      window.setTimeout(() => {
        this.registerFrontendValidator()
      }, 200)
      return
    }

    formBehavior.behavior.registerValidator(this.fieldName, this.validate.bind(this))
    formBehavior.behavior.registerSerializer(this.fieldName, this.serialize.bind(this))
  }

  destroy() {

  }

}
