// noinspection LanguageDetectionInspection

import $ from 'jquery'

export default class FlowerFilterMultiSelectBadge {
  constructor (element) {
    this.$element = $(element)
    this.$filterActiveBadge = this.$element.find('.filterActiveBadge');
    this.$checkboxes = this.$element.find('input[type=checkbox]');
    this.$resetButton = this.$element.find('.filterResetButton');
    this.$checkboxes.on('change', this.handleCheckboxChange.bind(this))
    this.$resetButton.on('click', this.handleResetClick.bind(this))
    this.updateUI()
  }

  handleResetClick(event) {
    event.preventDefault();
    this.$checkboxes.prop('checked', false).trigger('change')
  }

  handleCheckboxChange(event) {
    this.updateUI();
  }

  updateUI() {
    const $checkedBoxes = this.$checkboxes.filter(':checked');
    const count = $checkedBoxes.length;

    this.$filterActiveBadge.text(count);
    if (count > 0) {
      this.$filterActiveBadge.addClass('active');
      this.$resetButton.addClass('visible');
    } else {
      this.$filterActiveBadge.removeClass('active');
      this.$resetButton.removeClass('visible');
    }
  }

  destroy() {

  }
}
