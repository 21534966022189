import $ from "jquery";

export default class productGridFilterForm  {
  constructor (element) {
    this.$element = $(element)
    this.$element.on('submit', this.handleSubmit.bind(this))
  }

  handleSubmit() {
    this.$element.addClass('submitting');
  }

  destroy() {

  }
}
