import $ from 'jquery'
import Modal from 'bootstrap/js/src/modal'
import Behaviors from "../behaviors";
import formSubmit from "./formSubmit";

export default class addToBasketForm extends formSubmit {
  constructor (element) {
    super(element);
    this.$flyout = $('.miniBasket')
    this.cachedOriginalContent = {}
  }

  handleResponse (status, responseData, rawData) {
    if (responseData.hasOwnProperty('payload')) {
      if (responseData.payload.hasOwnProperty('refreshBasketFlyout')) {
        if (responseData.payload.refreshBasketFlyout) {
          const formBehavior = Behaviors.getBehaviorFromElement(this.$flyout.get(0))
          if (formBehavior) {
            formBehavior.behavior.refreshFlyoutContent()
          }
        }
      }

      if (responseData.payload.hasOwnProperty('showModalId')) {
        const requestedModalId = responseData.payload.showModalId;
        const $requestedModal = $(`#${requestedModalId}`);
        if ($requestedModal.length > 0) {
          const $modal = $(`#${responseData.payload.showModalId}`)

          const modal = new Modal($modal.get(0))
          if (responseData.payload.hasOwnProperty('modalData')) {
            const $modalBody = $modal.find('.modal-body')
            if (!this.cachedOriginalContent.hasOwnProperty(requestedModalId)) {
                this.cachedOriginalContent[requestedModalId] = $modalBody.html();
            }

            let modalContent = this.cachedOriginalContent[requestedModalId]
            const modalData = responseData.payload.modalData
            for (const parameterName in modalData) {
              modalContent = modalContent.replace(`%${parameterName}%`, modalData[parameterName])
            }
            $modalBody.html(modalContent)
          }

          modal.show()
          this.$form.removeClass('submitting')
          return
        }
      }
    }

    super.handleResponse(status, responseData, rawData)
  }


}
