import $ from 'jquery'
import Collapse from 'bootstrap/js/src/collapse'

export default class questionOptional {
  constructor (element) {
    this.$element = $(element)
    this.rule = this.$element.data('optionalRule')
    this.optionalId = this.$element.data('optionalId')
    this.$targetElement = $(`#${this.optionalId}`);

    this.resetOptionalFieldsOnClose = this.$element.data('resetOptionalFieldsOnClose')
    if (typeof this.resetOptionalFieldsOnClose === 'undefined') {
      this.resetOptionalFieldsOnClose = true
    }

    this.$targetElement.addClass('collapse')
    this.collapse = new Collapse(this.$targetElement, {toggle: false});
    this.$targetElement.get(0).addEventListener('shown.bs.collapse', this.handleCollapsingShown.bind(this))
    this.$targetElement.get(0).addEventListener('show.bs.collapse', this.handleCollapsingShow.bind(this))
    this.$targetElement.get(0).addEventListener('hidden.bs.collapse', this.handleCollapsingHidden.bind(this))
    this.$targetElement.get(0).addEventListener('hidde.bs.collapse', this.handleCollapsingHide.bind(this))

    this.isInTransition = false;

    this.updateUi();
  }

  handleCollapsingShow(event) {
    this.isInTransition = true;
    this.updateUi()
  }

  handleCollapsingHide(event) {
    this.isInTransition = true;
    this.updateUi()
  }

  handleCollapsingShown(event) {
    this.isInTransition = false;
    this.updateUi()
  }

  handleCollapsingHidden(event) {
    this.isInTransition = false;
    this.updateUi()
  }

  updateUi() {
    if (this.isInTransition) {
      return
    }
    switch (this.rule) {
      case 'selectIfOneSelected':
        this.selectIfOneSelected()
        break;
      case 'selectIfValue':
        const showIfValue = this.$element.data('optionalValue')
        this.selectIfValue(showIfValue);
        break;
      case 'selectIfNotValue':
        const hideIfValue = this.$element.data('optionalValue')
        this.selectIfValue(hideIfValue, true);
        break;
      case 'selectIfYes':
        this.selectIfValue('1');
        break;
    }
  }

  selectIfValue(showIfValue, inverse = false) {
    const $checkboxes = this.$element.find('input');
    const $select = this.$element.find('select');

    $.merge($checkboxes, $select).on('change', () => {
      if ($checkboxes.filter(`[value="${showIfValue}"]:checked`).length > 0 || $select.val() === showIfValue) {
        if (inverse) { this.hideOptionalField() } else { this.showOptionalField() }
      } else {
        if (inverse) { this.showOptionalField() } else { this.hideOptionalField() }
      }
    }).eq(0).trigger('change')
  }

  selectIfOneSelected () {
    const $checkboxes = this.$element.find('input');
    const $select = this.$element.find('select');
    $.merge($checkboxes, $select).on('change', () => {
        if ($checkboxes.filter(':checked').length > 0 || $select.val() !== '') {
          this.showOptionalField()
        } else {
          this.hideOptionalField()
        }
    }).eq(0).trigger('change')
  }

  showOptionalField() {
      this.collapse.show()
  }

  hideOptionalField() {
      this.collapse.hide()
      this.clearOptionalFields()
  }

  clearOptionalFields() {
    if (!this.resetOptionalFieldsOnClose) {
      return
    }

    // uncheck all radios
    this.$targetElement.find('input[type=radio]:checked').prop('checked', false).trigger('change')

    // if there is an radio with empty value: select it!
    this.$targetElement.find('input[type=radio][value=""]').prop('checked', true).trigger('change')

    // uncheck all checkboxes
    this.$targetElement.find('input[type=checkbox]:checked').prop('checked', false).trigger('change')

    this.$targetElement.find('input[type=text]').val('').trigger('change')
    this.$targetElement.find('textarea').val('').trigger('change')
    this.$targetElement.find('select').val('').trigger('change')
  }

  destroy() {

  }

}
