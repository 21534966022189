// noinspection LanguageDetectionInspection

import $ from 'jquery'

export default class FlowerFilterSingleSelectBadge {
  constructor (element) {
    this.$element = $(element)
    this.$filterActiveBadge = this.$element.find('.filterActiveBadge');
    this.$radios = this.$element.find('input[type=radio]');
    this.$resetButton = this.$element.find('.filterResetButton');
    this.$radios.on('change', this.handleCheckboxChange.bind(this))
    this.$resetButton.on('click', this.handleResetClick.bind(this))
    this.updateUI()
  }

  handleResetClick(event) {
    event.preventDefault();
    this.$radios.filter('[value=""]').prop('checked', true).trigger('change')
  }

  handleCheckboxChange(event) {
    this.updateUI();
  }

  updateUI() {
    if (this.$radios.filter(':checked').val()) {
      this.$filterActiveBadge.addClass('active');
      this.$resetButton.addClass('visible');
    } else {
      this.$filterActiveBadge.removeClass('active');
      this.$resetButton.removeClass('visible');
    }
  }

  destroy() {

  }
}
