import $ from 'jquery'

export default class workflowNavigation {
  constructor (element) {
    this.$element = $(element)
    this.formId = this.$element.data('formId')

    this.$form = $(`form#${this.formId}`)
    this.workflowAction = this.$element.data('workflowAction')

    this.$workflowActionField = this.$form.find('input[name=workflowNavigation]')
    if (this.$workflowActionField.length < 1) {
      console.error('workflowNavigation field not found!', element, this.formId, this.$form, this.workflowAction, this.$workflowActionField)
      // console.log(this.$workflowActionField = this.$form.find('input'))
    }

    this.activateButtonAfterSomeTime()
    this.initiateHandler()
  }

  initiateHandler() {
    this.$element.on('click.workflowNavigation', this.handleClick.bind(this))
  }

  disableButton () {
    this.$element.prop('disabled', true)
  }

  activateButtonAfterSomeTime () {
    window.setTimeout(this.activateButton.bind(this), 1000)
  }

  activateButton () {
    this.$element.prop('disabled', false)
  }

  handleClick (event) {
    event.preventDefault()
    if (this.$element.prop('disabled')) {
      return
    }
    this.disableButton()
    this.activateButtonAfterSomeTime()

    this.$workflowActionField.val(this.workflowAction)
    this.$form.trigger('submit')
  }
  destroy() {
    this.$element.off('click.workflowNavigation')
  }

}
