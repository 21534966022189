import $ from 'jquery'

export default class checkboxTableMultiSetter {
  constructor (element) {
    this.$element = $(element)

    this.$allCheckbox = this.$element.find('input[type=checkbox][name="allCheckboxes"]')
    this.$allCheckbox.on('change', this.handleAllCheckboxesChange.bind(this))

    this.$form = this.$element.closest('form')

    this.checkboxNameStartsWith = this.$element.data('checkboxNameStartsWith')
    if (this.checkboxNameStartsWith) {
      this.$checkboxes = this.$form.find(`input[type=checkbox][name^="${this.checkboxNameStartsWith}"]`)
    } else {
      this.$checkboxes = this.$form.find(`input[type=checkbox]`)
    }

    this.$checkboxes = this.$checkboxes.not(this.$allCheckbox)
    this.$checkboxes.on('change', this.setInitialState.bind(this))
    this.setInitialState()
  }

  setInitialState (event) {
    let allChecked = true
    this.$checkboxes.each((idx, item) => {
      const $box = $(item)
      if (!$box.prop('checked')) {
        allChecked = false
      }
    })

    if (allChecked) {
      this.$allCheckbox.prop('checked', true)
    } else {
      this.$allCheckbox.prop('checked', false)
    }
  }

  handleAllCheckboxesChange (event) {
    const allIsChecked = this.$allCheckbox.prop('checked')
    this.$checkboxes.each((idx, item) => {
      const $box = $(item)
      $box.prop('checked', allIsChecked)
      $box.trigger('change')
    })
  }
}
