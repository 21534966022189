import $ from 'jquery'
import { TempusDominus } from '@eonasdan/tempus-dominus'
import { DateConfig } from '../tempusDominusConfig'

export default class datePicker {
  constructor (element) {
    this.$element = $(element)
    this.picker = null
    this.initializePicker()
    this.registerButton()
  }

  initializePicker () {
    this.picker = new TempusDominus(this.$element.get(0), DateConfig)
  }

  registerButton () {
    this.$group = this.$element.closest('.input-group')
    this.$button = this.$group.find('button.openPicker')
    this.$button.on('click', this.handleButtonClick.bind(this))

    // $(document).on('click', this.handleDocumentClick.bind(this))
  }

  handleDocumentClick (event) {
    // console.log('handleDocumentClick')
    this.picker.hide()
  }

  handleButtonClick (event) {
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
    this.picker.toggle()
  }
}
